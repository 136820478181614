.app {
	font-family: "Roboto", Sans-serif;
	line-height: 1.8;

	.app-header {
		top: 0;
		left: 0;
		position: absolute;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.sidebar-logo {
			width: 150px;
			padding: 10px;
		}

		.header-sections {
			display: flex;
			column-gap: 10px;
			margin: 0 30px;

			a {
				text-decoration: none;
				font-size: 16px;
				font-weight: 600;
				color: black;

				&:hover {
					border-bottom: 2px solid black;
				}
			}

			.horizontal-separator {
				width: 0;
				border: 1px solid black
			}
		}
	}

	.backgroun-image-section {
		background-attachment: fixed;
		background-image: url('./assets/headerImage.jpg');
		width: 100%;
		height: 50vh;
		background-color: white;
		background-position: bottom center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
	}

	#our-services {
		padding: 10px 250px;
		height: 50vh;

		h1 {
			font-size: 2rem;
			color: #3469AC;
		}

	}

	#mission-and-vision {
		padding: 10px 250px;
		background-color: #EDEDED;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		text-align: justify;
		height: 50vh;
		column-gap: 15px;

		h1 {
			font-size: 2rem;
			color: #3469AC;
			flex: 0.5;
			height: 10rem;
		}

		.mission {
			border-radius: 10px;
			background-color: white;
			padding: 20px;
			height: fit-content;
			flex: 0.5;
			height: 10rem;
		}

		.vision {
			border-radius: 10px;
			background-color: white;
			padding: 20px;
			height: fit-content;
			flex: 0.5;
			height: 10rem;
		}
	}

	.app-footer {
		display: flex;
		align-items: center;
		justify-content: space-around;
		background-color: #3469AC;
		height: 10rem;
		color: white;

		.contact-us {
			display: flex;
			align-items: center;
			column-gap: 5px;

			.whatsapp-logo {
				width: 25px;
			}
		}
	}
}

.vertical-separator {
	height: 0;
	border: 1px solid #3469AC;
	width: 5%;
	margin: 20px 0;
}